<template>
    <main class="main">
        <div class="container-fluid px-0" data-layout="container">

            <aside-main/>

            <header-main/>

            <div class="content">
                <form class="mb-9" v-on:submit.prevent="createItem">
                    <div class="row justify-content-between align-items-end g-3 mb-5">
                        <div class="col-12 col-sm-auto col-xl-8">
                            <h2>Создать Платеж</h2>
                        </div>
                    </div>
                    <div class="row g-5">
                        <div class="col-xl-12">
                            <div class="row g-3">

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating v-select-medium" :class="{'v-select-error': errors.user}">
                                        <vue-select
                                            @input="errors.user = null, changeUser()"
                                            @search="searchUsers"
                                            v-model="data.user"
                                            :options="utils.users" 
                                            placeholder="Пользователь"
                                            label="email">
                                            <template v-slot:no-options="{ search, searching }">
                                                <template v-if="searching && search.length >= 3">
                                                    Ничего не найдено по запросу <em>{{ search }}</em>.
                                                </template>
                                                <span v-else>Введите не менее 3 символов</span>
                                            </template>
                                        </vue-select>
                                        <label>Пользователь</label>
                                        <div class="invalid-feedback">{{errors.user}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating v-select-medium" :class="{'v-select-error': errors.merchant}">
                                        <vue-select
                                            @input="errors.merchant = null"
                                            v-model="data.merchant"
                                            :options="utils.merchants" 
                                            placeholder="Валюта"
                                            label="currency">
                                            <template v-slot:no-options="{ search, searching }">
                                              <template v-if="searching">
                                                 Ничего не найдено по запросу <em>{{ search }}</em>.
                                              </template>
                                            </template>
                                        </vue-select>
                                        <label>Валюта</label>
                                        <div class="invalid-feedback">{{errors.merchant}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating">
                                        <input
                                            v-model="data.amount"
                                            @input="errors.amount = null"
                                            :class="{'is-invalid': errors.amount}"
                                            placeholder="Сумма"
                                            class="form-control" 
                                            type="text"/>
                                        <label>Сумма</label>
                                        <div class="invalid-feedback">{{errors.amount}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating">
                                        <input
                                            v-model="data.wallet"
                                            @input="errors.wallet = null"
                                            :class="{'is-invalid': errors.wallet}"
                                            placeholder="Кошелек"
                                            class="form-control" 
                                            type="text"/>
                                        <label>Кошелек</label>
                                        <div class="invalid-feedback">{{errors.wallet}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating">
                                        <date-range-picker
                                            :class="{'is-invalid': errors.period}"
                                            @update="errors.period = null"
                                            :locale-data="datepiker"
                                            :opens="pikerparams.opens"
                                            :always-show-calendars="pikerparams.showcalendars"
                                            :close-on-esc="pikerparams.closeonesc"
                                            :auto-apply="pikerparams.autoapply"
                                            :time-picker="pikerparams.timePicker"
                                            :time-picker24-hour="pikerparams.timePicker24Hour"
                                            v-model="data.period">
                                            <template #ranges="ranges">
                                              <div class="ranges">
                                                <ul>
                                                  <li 
                                                    v-for="(range, name, key) in pikerranges" 
                                                    :key="name" 
                                                    @click="ranges.clickRange(range, key)">
                                                        {{name}}
                                                  </li>
                                                </ul>
                                              </div>
                                            </template>
                                        </date-range-picker>

                                        <label>Период</label>
                                        <div class="invalid-feedback">{{errors.period}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating v-select-medium" :class="{'v-select-error': errors.status}">
                                        <vue-select
                                            disabled
                                            @input="errors.status = null"
                                            v-model="data.status"
                                            :options="utils.statuses" 
                                            placeholder="Статус"
                                            label="name">
                                            <template v-slot:no-options="{ search, searching }">
                                              <template v-if="searching">
                                                 Ничего не найдено по запросу <em>{{ search }}</em>.
                                              </template>
                                            </template>
                                        </vue-select>
                                        <label>Статус</label>
                                        <div class="invalid-feedback">{{errors.status}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-3 col-md-3">
                                    <button class="btn btn-primary px-5 w-100 text-nowrap" type="submit" :class="{'disabled': btn_preloader}">
                                        <span class="spinner-border spinner-border-sm spinner-border-custom" role="status" v-if="btn_preloader"></span>
                                        <span>Создать</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                
                <footer-main/>

            </div>

        </div>
    </main>
</template>

<script>
export default {
    data () {
        return {
            btn_preloader: false,
            data: {
                user: null,
                merchant: null,
                amount: null,
                wallet: null,
                period: {
                    startDate: this.$moment().set({date: this.$moment().get('date') - 28}).format('Y-MM-DD 00:00:00'),
                    endDate: this.$moment().set({date: this.$moment().get('date') - 14}).format('Y-MM-DD 00:00:00')
                },
                status: {
                    name: 'Выполнен',
                    key: 1
                }
            },
            errors: {
                user: false,
                merchant: false,
                amount: false,
                wallet: false,
                period: false,
                status: false
            },
            utils: {
                users: [],
                merchants: [],
                statuses: [
                    {
                        name: 'В ожидании',
                        key: 0
                    },
                    {
                        name: 'Выполнен',
                        key: 1
                    },
                    {
                        name: 'Отклонен',
                        key: 2
                    }
                ]
            },
            datepiker: {
                direction: 'ltr',
                format: 'yyyy.mm.dd HH:MM',
                separator: ' - ',
                applyLabel: 'Применить',
                cancelLabel: 'Отмена',
                weekLabel: 'W',
                customRangeLabel: 'Настраиваемый диапазон',
                daysOfWeek: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
                monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
                firstDay: 0
            },
            pikerparams: {
                showcalendars: true,
                closeonesc: true,
                autoapply: false,
                timePicker: true,
                timePicker24Hour: true,
                opens: 'right'
            },
            pikerranges: {
                'Один день': [this.$moment().toDate(), this.$moment().set({date: this.$moment().get('date') + 1}).toDate()],
                'Одна неделя': [this.$moment().toDate(), this.$moment().set({date: this.$moment().get('date') + 7}).toDate()],
                'Один месяц': [this.$moment().toDate(), this.$moment().set({date: this.$moment().get('date') + 30}).toDate()],
                'Два месяца': [this.$moment().toDate(), this.$moment().set({date: this.$moment().get('date') + 60}).toDate()],
                'Три месяца': [this.$moment().toDate(), this.$moment().set({date: this.$moment().get('date') + 90}).toDate()],
                'Пол года': [this.$moment().toDate(), this.$moment().set({date: this.$moment().get('date') + 180}).toDate()],
                'Один год': [this.$moment().toDate(), this.$moment().set({date: this.$moment().get('date') + 365}).toDate()]
            }
        }
    },
    methods: {
        createItem () {
            if(!this.data.user) {
                this.errors.user = 'Это поле обязательно'
                return
            }
            if(!this.data.merchant) {
                this.errors.merchant = 'Это поле обязательно'
                return
            }
            if(!this.data.amount) {
                this.errors.amount = 'Это поле обязательно'
                return
            }
            if(!this.data.wallet) {
                this.errors.wallet = 'Это поле обязательно'
                return
            }
            if(!this.data.period) {
                this.errors.period = 'Это поле обязательно'
                return
            }
            if(!this.data.status) {
                this.errors.status = 'Это поле обязательно'
                return
            }
            
            this.btn_preloader = true
            this.$axios.post('/payments/withdrawal/create', JSON.stringify({
                user: this.data.user.id,
                merchant: this.data.merchant.currency,
                amount: this.data.amount,
                wallet: this.data.wallet,
                period: {
                    start: this.$moment(this.data.period.startDate).format('YYYY-MM-DD H:mm:ss'),
                    end: this.$moment(this.data.period.endDate).format('YYYY-MM-DD H:mm:ss')
                },
                status: this.data.status.key
            }))
            .then((response) => {
                this.btn_preloader = false
                response = response.data
                switch (response.status) {
                    case "error":
                        this.$toast.error(response.message)
                        break
                    case "success":
                        this.$router.push({name: 'PaymentsItems'})
                        this.$toast.success(response.message)

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    if('field' in response) {
                        this.errors[response.field] = response.message
                    }
                }
            })
        },
        searchUsers (search, loading) {
            if(search.length >= 3) {
                loading(true)
                 this.$axios.post('/users/search/identificator', JSON.stringify({
                    search: search
                 }))
                .then((response) => {
                    response = response.data
                    switch (response.status) {
                        case "error":
                            this.$toast.error(response.message)
                            break
                        case "success":
                            loading(false)
                            this.utils.users = []
                            for (let user of response.data) {
                                this.utils.users.push({
                                    id: user.id,
                                    email: user.email,
                                    wallet: user.wallet
                                })
                            }

                            this.setUser(response.user)
                            break
                        default:
                            this.$toast.error('Unknown error')
                    }
                }).catch((error) => {
                    let response = error.response.data
                    this.setUser(response.user)

                    if('status' in response && response.status == 'error') {
                        if('field' in response) {
                            this.errors[response.field] = response.message
                        } else {
                            this.$toast.error(response.message)
                        }
                    }
                })
            }
        },
        getMerchants () {
            this.$axios.post('/merchants/items')
            .then((response) => {
                this.preloader = false
                response = response.data
                switch (response.status) {
                    case 'error':
                        this.$toast.error(response.message)
                        break
                    case 'success':
                        for (let item of response.data) {
                            this.utils.merchants.push({
                                name: item.name,
                                currency: item.currency,
                                id: item.id
                            })
                        }

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })     
        },
        changeUser () {
            this.data.wallet = this.data.user.wallet
        }
    },
    beforeMount() {
        this.getMerchants()
    }
}
</script>

<style lang="css" scoped>
    ::v-deep .vs__clear {
        display: none;
    }
</style>
