import Vue from 'vue'
import Vuex from 'vuex'
import Localization from '../Langs'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    theme: 'white',
    loaded: false,
    localization: {},
    lang: null,
    user: {},
    notifications: {
      users: 0,
    },
    search: {
      user: null
    }
  },
  mutations: {
    setTheme(state, theme) {
      if(theme == 'dark') {
        document.documentElement.classList.add('dark')
      } else {
        document.documentElement.classList.remove('dark')
      }
      state.theme = theme
    },
    setLoaded(state, loaded) {
      state.loaded = loaded
    },
    setLocalization(state, lang) {
    	state.lang = lang
    	state.localization = Localization.GetLocalization(lang)
    },
    setUser(state, data) {
      if(data && ('auth' in data)) {
        state.user = data
      } else {
        state.user = {}
      }
    },
    setNotificationUsers(state, count) {
      state.notifications.users = count
    },
  }
})