<template>
    <main class="main">
        <div class="container-fluid px-0" data-layout="container">

            <aside-main/>

            <header-main/>

            <div class="content">

                <div class="card-body p-0 spinner-center" v-if="preloader">
                    <div class="p-4 code-to-copy">
                        <div class="spinner-border" role="status"></div>
                    </div>
                </div>

                <form class="mb-9" v-on:submit.prevent="updateItem" v-if="!preloader">
                    <div class="row justify-content-between align-items-end g-3 mb-5">
                        <div class="col-12 col-sm-auto col-xl-8">
                            <h2>Карточка пользователя</h2>
                        </div>
                        <div class="col-12 col-sm-auto col-xl-4">
                            <div class="d-flex">
                                <button
                                    :class="{'disabled': btn_preloader}"
                                    class="btn btn-primary px-5 w-100 text-nowrap" 
                                    type="submit">
                                    <span class="spinner-border spinner-border-sm spinner-border-custom" role="status" v-if="btn_preloader"></span>
                                    <span>Сохранить изменения</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="row g-5">
                        <div class="col-xl-8">
                            <h4 class="fs-1 mb-4">Основная информация</h4>
                            <div class="row g-3">
                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating">
                                        <input 
                                            v-model="user.email"
                                            @input="errors.user.email = null"
                                            :class="{'is-invalid': errors.user.email}"
                                            class="form-control"
                                            id="email" 
                                            type="text" 
                                            placeholder="Email"/>
                                        <label for="email">Email</label>
                                        <div class="invalid-feedback">{{errors.user.email}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating v-select-medium merchant_select" :class="{'v-select-error': errors.user.status}">
                                        <vue-select
                                            v-model="user.status"
                                            @input="errors.user.status = null"
                                            :options="utils.statuses"
                                            placeholder="Статус"
                                            label="name">
                                            <template v-slot:no-options="{ search, searching }">
                                              <template v-if="searching">
                                                 Ничего не найдено по запросу <em>{{ search }}</em>.
                                              </template>
                                            </template>
                                        </vue-select>
                                        <label>Статус</label>
                                        <div class="invalid-feedback">{{errors.user.status}}</div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-12">
                                    <div class="form-floating v-select-medium merchant_select" :class="{'v-select-error': errors.user.permissions}">
                                        <vue-select
                                            multiple
                                            v-model="user.permissions"
                                            @input="errors.user.permissions = null"
                                            :options="utils.permissions"
                                            placeholder="Уровень доступа"
                                            label="name">
                                            <template v-slot:no-options="{ search, searching }">
                                              <template v-if="searching">
                                                 Ничего не найдено по запросу <em>{{ search }}</em>.
                                              </template>
                                            </template>
                                        </vue-select>
                                        <label>Уровень доступа</label>
                                        <div class="invalid-feedback">{{errors.user.permissions}}</div>
                                    </div>
                                </div>

                                <h4 class="fs-1 mt-7">Персональные данные</h4>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating v-select-medium merchant_select" :class="{'v-select-error': errors.user.personal.country}">
                                        <vue-select
                                            v-model="user.personal.country"
                                            @input="errors.user.personal.country = null"
                                            :options="utils.countries"
                                            placeholder="Страна"
                                            label="name">
                                            <template v-slot:no-options="{ search, searching }">
                                              <template v-if="searching">
                                                 Ничего не найдено по запросу <em>{{ search }}</em>.
                                              </template>
                                            </template>
                                        </vue-select>
                                        <label>Страна</label>
                                        <div class="invalid-feedback">{{errors.user.personal.country}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating">
                                        <input 
                                            v-model="user.personal.telegram"
                                            @input="errors.user.personal.telegram = null"
                                            :class="{'is-invalid': errors.user.personal.telegram}"
                                            class="form-control" 
                                            id="telegram" 
                                            type="text" 
                                            placeholder="Телеграм ID"/>
                                        <label for="telegram">Телеграм ID</label>
                                        <div class="invalid-feedback">{{errors.user.personal.telegram}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating">
                                        <input 
                                            v-model="user.percent"
                                            @input="errors.user.percent = null"
                                            :class="{'is-invalid': errors.user.percent}"
                                            class="form-control" 
                                            type="text" 
                                            placeholder="Процент"/>
                                        <label>Процент</label>
                                        <div class="invalid-feedback">{{errors.user.percent}}</div>
                                    </div>
                                </div>


                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating v-select-medium merchant_select" :class="{'v-select-error': errors.user.ref_id}">
                                        <vue-select
                                            v-model="user.ref_id"
                                            @input="errors.user.ref_id = null"
                                            @search="searchUsers"
                                            :options="utils.users"
                                            placeholder="Реферер"
                                            class="referrer"
                                            label="email">
                                            <template v-slot:option="option">
                                                <span>{{ option.email }}</span>
                                            </template>
                                            <template v-slot:no-options="{ search, searching }">
                                                <template v-if="searching && search.length >= 3">
                                                    Ничего не найдено по запросу <em>{{ search }}</em>.
                                                </template>
                                                <span v-else>Введите не менее 3 символов</span>
                                            </template>
                                        </vue-select>
                                        <label>Реферер</label>
                                        <div class="invalid-feedback">{{errors.user.ref_id}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating">
                                        <input 
                                            v-model="user.ref_percent"
                                            @input="errors.user.ref_percent = null"
                                            :class="{'is-invalid': errors.user.ref_percent}"
                                            class="form-control" 
                                            type="text" 
                                            placeholder="Процент с реферала"/>
                                        <label>Процент с реферала</label>
                                        <div class="invalid-feedback">{{errors.user.ref_percent}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating">
                                        <input 
                                            v-model="user.personal.wallet"
                                            @input="errors.user.personal.wallet = null"
                                            :class="{'is-invalid': errors.user.personal.wallet}"
                                            class="form-control" 
                                            id="wallet" 
                                            type="text" 
                                            placeholder="Платежные реквизиты"/>
                                        <label for="wallet">Платежные реквизиты</label>
                                        <div class="invalid-feedback">{{errors.user.personal.wallet}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-12 mb-3">
                                    <div class="form-floating partner-link-raw">
                                        <input
                                            disabled
                                            v-model="ref_link"
                                            placeholder="Реферальная ссылка" 
                                            type="text" 
                                            class="form-control partner-link-input" />
                                        <label>Реферальная ссылка</label>
                                        <button
                                            @click="copyLink"
                                            v-tippy="{arrow : true, arrowType: 'round'}"
                                            content="Скопировать ссылку"
                                            type="button"
                                            class="btn btn-soft-default tournament-button-right">
                                            <span class="vertical-center-item"><i class="fa-regular fa-copy"></i></span>
                                        </button>
                                    </div>
                                </div>


                                <h4 class="fs-1 mt-7">Отпечаток при регистрации</h4>

                                <div class="col-sm-6 col-md-4">
                                    <div class="form-floating">
                                        <input 
                                            v-model="user.geo.ip"
                                            class="form-control" 
                                            id="geo_ip" 
                                            type="text" 
                                            placeholder="IP адрес"
                                            disabled/>
                                        <label for="geo_ip">IP адрес</label>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-4">
                                    <div class="form-floating">
                                        <input 
                                            v-model="user.geo.country"
                                            class="form-control" 
                                            id="geo_country" 
                                            type="text" 
                                            placeholder="Страна"
                                            disabled/>
                                        <label for="geo_country">Страна</label>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-4">
                                    <div class="form-floating">
                                        <input 
                                            v-model="user.geo.city"
                                            class="form-control" 
                                            id="geo_city" 
                                            type="text" 
                                            placeholder="Город"
                                            disabled/>
                                        <label for="geo_city">Город</label>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-4">
                                    <div class="form-floating">
                                        <input 
                                            v-model="user.agent.device"
                                            class="form-control" 
                                            id="agent_device" 
                                            type="text" 
                                            placeholder="Девайс"
                                            disabled/>
                                        <label for="agent_device">Девайс</label>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-4">
                                    <div class="form-floating">
                                        <input 
                                            v-model="user.agent.browser.name"
                                            class="form-control" 
                                            id="agent_browser" 
                                            type="text" 
                                            placeholder="Браузер"
                                            disabled/>
                                        <label for="agent_browser">Браузер</label>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-4">
                                    <div class="form-floating">
                                        <input 
                                            v-model="user.agent.platform.name"
                                            class="form-control" 
                                            id="agent_platform" 
                                            type="text" 
                                            placeholder="Платформа"
                                            disabled/>
                                        <label for="agent_platform">Платформа</label>
                                    </div>
                                </div>

                            </div>
                        </div>


                        <div class="col-xl-4">
                            <h4 class="fs-1 mb-4">Активность</h4>

                            <div class="form-check mb-2">
                                <label class="form-check-label fw-normal">
                                    Дата регистрации: {{ $moment(user.created_at).format('YYYY-MM-DD H:mm:ss') }}
                                </label>
                            </div>
                            <div class="form-check mb-2">
                                <label class="form-check-label fw-normal">
                                    Последнее обновление: {{ $moment(user.updated_at).format('YYYY-MM-DD H:mm:ss') }}
                                </label>
                            </div>

                            <h4 class="fs-1 mb-4">Сменить пароль</h4>

                            <form class="mb-9" v-on:submit.prevent="changePassword">
                                <div class="row g-3 mb-4">
                                    <div class="col-sm-6 col-md-12">
                                        <div class="form-floating">
                                            <input
                                                v-model="password.password"
                                                @input="errors.password.password = null"
                                                :class="{'is-invalid': errors.password.password}"
                                                class="form-control" 
                                                id="password" 
                                                type="password" 
                                                placeholder="Новый пароль"/>
                                            <label for="password">Новый пароль</label>
                                            <div class="invalid-feedback">{{errors.password.password}}</div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-md-12">
                                        <div class="form-floating">
                                            <input 
                                                v-model="password.password_confirmation"
                                                @input="errors.password.password_confirmation = null"
                                                :class="{'is-invalid': errors.password.password_confirmation}"
                                                class="form-control" 
                                                id="password_confirmation" 
                                                type="password" 
                                                placeholder="Подтвердите новый пароль"/>
                                            <label for="password_confirmation">Подтвердите новый пароль</label>
                                            <div class="invalid-feedback">{{errors.password.password_confirmation}}</div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-md-12">
                                        <div class="d-flex">
                                            <button
                                                :class="{'disabled': btn_preloader}"
                                                class="btn btn-primary px-5 w-100 text-nowrap" 
                                                type="submit">
                                                <span class="spinner-border spinner-border-sm spinner-border-custom" role="status" v-if="btn_preloader"></span>
                                                <span>Сменить пароль</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>

                    </div>
                </form>
                
                <footer-main/>

            </div>

        </div>
    </main>
</template>

<script>
export default {
    props: ['id'],
    data () {
        return {
            preloader: true,
            btn_preloader: false,
            ref_link: null,
            user: null,
            password: {
                password: null,
                password_confirmation: null
            },
            utils: {
                users: [],
                permissions: [],
                countries: [],
                statuses: [
                    {
                        name: 'Активный',
                        key: 1
                    },
                    {
                        name: 'Заблокирован',
                        key: 0
                    },
                    {
                        name: 'На модерации',
                        key: 2
                    }
                ]
            },
            errors: {
                user: {
                    email: false,
                    percent: false,
                    ref_id: false,
                    ref_percent: false,
                    personal: {
                        telegram: false,
                        phone: false,
                        country: false,
                        wallet: false
                    },
                    permissions: false,
                    status: false
                },
                password: {
                    password: false,
                    password_confirmation: false
                }
            },
            datepiker: {
                direction: 'ltr',
                format: 'yyyy.mm.dd',
                separator: ' - ',
                applyLabel: 'Применить',
                cancelLabel: 'Отмена',
                weekLabel: 'W',
                customRangeLabel: 'Настраиваемый диапазон',
                daysOfWeek: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
                monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
                firstDay: 0
            },
            pikerparams: {
                showcalendars: false,
                closeonesc: true,
                autoapply: true,
                single: true,
                ranges: false,
                dropdowns: true,
                maxdate: this.$moment().set({year: this.$moment().get('year') - 18}).format('Y-MM-DD'),
                opens: 'right'
            }
        }
    },
    methods: {
        async getItem () {
            await this.$axios.post('/users/item/' + this.$props.id)
            .then((response) => {
                this.preloader = false
                response = response.data
                switch (response.status) {
                    case 'error':
                        this.$toast.error(response.message)
                        break
                    case 'success':
                        this.user = response.data

                        this.user.status = this.utils.statuses.find(row => row.key == this.user.status)

                        this.user.personal.country = this.utils.countries.find(row => row.key == this.user.personal.country)

                        this.ref_link = `https://${this.$store.state.user.data.link}/redirect?ref_id=${this.user.id}&subid={SUBID}`

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })     
        },
        updateItem () {
            this.btn_preloader = true

            var permissions = []

            for (let permission of this.user.permissions) {
                permissions.push(permission.slug)
            }

            this.$axios.post('/users/update', JSON.stringify({
                id: this.$props.id,
                email: this.user.email,
                percent: this.user.percent,
                ref_id: this.user.ref_id ? this.user.ref_id.id : 0,
                ref_percent: this.user.ref_percent,
                personal: {
                    telegram: this.user.personal.telegram,
                    country: this.user.personal.country ? this.user.personal.country.key : null,
                    wallet: this.user.personal.wallet
                },
                permissions: permissions,
                status: this.user.status.key
            }))
            .then((response) => {
                this.btn_preloader = false
                response = response.data
                switch (response.status) {
                    case 'error':
                        this.$toast.error(response.message)
                        break
                    case 'success':
                        this.$toast.success(response.message)

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                this.btn_preloader = false
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    if('field' in response) {
                        this.errorByPatch(this.errors.user, response.field, response.message)
                    } else {
                        this.$toast.error(response.message)
                    }
                }
            })
        },
        searchUsers (search, loading) {
            if(search.length >= 3) {
                loading(true)
                 this.$axios.post('/users/search/identificator', JSON.stringify({
                    search: search
                 }))
                .then((response) => {
                    response = response.data
                    switch (response.status) {
                        case "error":
                            this.$toast.error(response.message)
                            break
                        case "success":
                            loading(false)
                            this.utils.users = []
                            for (let user of response.data) {
                                this.utils.users.push({
                                    id: user.id,
                                    email: user.email
                                })
                            }

                            this.setUser(response.user)
                            break
                        default:
                            this.$toast.error('Unknown error')
                    }
                }).catch((error) => {
                    let response = error.response.data
                    this.setUser(response.user)

                    if('status' in response && response.status == 'error') {
                        if('field' in response) {
                            this.errors[response.field] = response.message
                        } else {
                            this.$toast.error(response.message)
                        }
                    }
                })
            }
        },
        changePassword () {
            if(!this.password.password) {
                this.errors.password.password = 'Это поле обязательно'
                return
            }
            if(!this.password.password_confirmation) {
                this.errors.password.password_confirmation = 'Это поле обязательно'
                return
            }

            this.btn_preloader = true
            this.$axios.post('/users/changepassword/' + this.$props.id, JSON.stringify(this.password))
            .then((response) => {
                this.btn_preloader = false
                response = response.data
                switch (response.status) {
                    case 'error':
                        this.$toast.error(response.message)
                        break
                    case 'success':
                        this.password = {
                            password: null,
                            password_confirmation: null
                        }
                        this.$toast.success(response.message)

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                this.btn_preloader = false
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    if('field' in response) {
                        this.errors.password[response.field] = response.message
                    } else {
                        this.$toast.error(response.message)
                    }
                }
            })
        },
        errorByPatch (layer, path, value) {
            path = path.split('.')

            path.forEach((value, index) => {
                if (!isNaN(value)) {
                    path[index] = parseInt(value)
                }
            })

            for (var i = 0; i < path.length; i++) {
                if (value != null && i + 1 === path.length) {
                    layer[path[i]] = value
                }
                layer = layer[path[i]]
            }
            return layer
        },
        getPermissions () {
            this.$axios.post('/users/permissions/items')
            .then((response) => {
                response = response.data
                switch (response.status) {
                    case 'error':
                        this.$toast.error(response.message)
                        break
                    case 'success':
                        for (let item of response.data) {
                            this.utils.permissions.push({
                                name: item.name,
                                slug: item.slug
                            })
                        }

                        var permissions = []

                        for (var permission of this.user.permissions) {
                            let item = this.utils.permissions.find(row => row.slug == permission)
                            permissions.push(item)
                        }

                        this.user.permissions = permissions

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)
                
                if('status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })     
        },
        async getCountries () {
            await this.$axios.post('/utils/countries/items')
            .then((response) => {
                response = response.data
                switch (response.status) {
                    case 'error':
                        this.$toast.error(response.message)
                        break
                    case 'success':

                        for(let item in response.data) {
                            this.utils.countries.push({
                                name: response.data[item],
                                key: item
                            })
                        }

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)
                
                if('status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })     
        },
        copyLink () {
            navigator.clipboard.writeText(this.ref_link)
            this.$toast.success('Скопировано')
        }
    },
    async beforeMount() {
        await this.getCountries()
        await this.getItem()
        this.getPermissions()
    }
}
</script>

<style lang="css" scoped>
    ::v-deep .vs__clear {
        display: none;
    }
    ::v-deep .referrer .vs__clear {
        display: block;
    }
</style>
